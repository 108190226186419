import { TCountryCode } from 'types/country-code';
import { TLanguageShorthand } from 'types/language-shorthand';
import { isBrowser } from './environment';

const languages: {
    [key in TLanguageShorthand]: {
        path: string;
        fullName: string;
        stdCountryCode: TCountryCode;
    };
} = {
    de: {
        path: '/',
        fullName: 'Deutsch',
        stdCountryCode: 'de-DE',
    },
    en: {
        path: '/en/',
        fullName: 'English',
        stdCountryCode: 'en-US',
    },
};

const DEFAULT_LANG = 'de';
const SECOND_DEFAULT_LANG = 'en';

const getStdCountryCode = (lang: TLanguageShorthand) =>
    languages[lang].stdCountryCode;

const isValidLang = (lang: TLanguageShorthand) =>
    Object.keys(languages).find((_) => _ === lang) !== undefined;

const getPathForLang = (lang: TLanguageShorthand) => {
    if (!isValidLang(lang)) return languages[DEFAULT_LANG]['path'];
    return languages[lang]['path'];
};

const getLangFromPath = () => {
    if (!isBrowser()) return null;

    //get first segment of path
    const langSegmentFromPath = window.location.pathname
        .split('/')
        .filter((pathSegment) => pathSegment !== '')
        .shift();

    // if that part wasn't defined, return null
    if (langSegmentFromPath === undefined) return null;

    // if not, look it up in languages array,
    // since it could also be a random slug
    return isValidLang(langSegmentFromPath as TLanguageShorthand)
        ? langSegmentFromPath
        : null;
};

export {
    languages,
    DEFAULT_LANG,
    SECOND_DEFAULT_LANG,
    isValidLang,
    getPathForLang,
    getLangFromPath,
    getStdCountryCode,
};
