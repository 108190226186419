import { getCookie } from './cookie/cookie';

const isBrowser = () => typeof window !== 'undefined';

const isLocalEnv = () => window.location.hostname === 'localhost';

const isProductiveEnv = () => window.location.hostname === 'www.mesono.de';

const isDebugTrackingMode = () => {
    if (!isBrowser()) return false;
    return !!getCookie('DEBUG_TRACKING');
};

export { isBrowser, isLocalEnv, isProductiveEnv, isDebugTrackingMode };
