import React from 'react';
import { Location } from '@reach/router';
import { Helmet } from 'react-helmet';
import { getPageByUrl } from '../../helpers/page-overview';

const Hreflangs: React.FC = () => (
    <Location>
        {(locationProps) => {
            const { pathname = '' } = locationProps.location;
            const currentPage = getPageByUrl(pathname);

            return (
                <Helmet>
                    {currentPage &&
                        Object.keys(currentPage.paths).map((lang) => (
                            <link
                                key={lang}
                                rel="alternate"
                                hrefLang={lang}
                                href={`${locationProps.location.origin}${currentPage.paths[lang]}`}
                            />
                        ))}
                </Helmet>
            );
        }}
    </Location>
);

export { Hreflangs };
