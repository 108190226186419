import { IPage } from 'types/page';
import { allPages } from './data';

const getPageByUrl = (url: string): IPage =>
    allPages.find((page) =>
        Object.keys(page.paths).find((lang) => page.paths[lang] === url)
    );

const getAllPages = () => allPages;

export { getPageByUrl, getAllPages };
