import { IPage } from 'types/page';

const allPages: IPage[] = [
    {
        key: 'apartments-overview',
        paths: {
            de: '/apartments/uebersicht/',
            en: '/en/apartments/overview/',
        },
    },
    {
        key: 'surroundings-stade',
        paths: {
            de: '/umgebung/stade/',
            en: '/en/surroundings/stade/',
        },
    },
    {
        key: 'surroundings-buxtehude',
        paths: {
            de: '/umgebung/buxtehude/',
            en: '/en/surroundings/buxtehude/',
        },
    },
    {
        key: 'surroundings-bockelkathen',
        paths: {
            de: '/umgebung/bockelkathen/',
            en: '/en/surroundings/bockelkathen/',
        },
    },
    {
        key: 'surroundings-sassnitz',
        paths: {
            de: '/umgebung/sassnitz/',
            en: '/en/surroundings/sassnitz/',
        },
    },
    {
        key: 'contact',
        paths: {
            de: '/kontakt/',
            en: '/en/contact/',
        },
    },
    {
        key: 'downloads',
        paths: {
            de: '/downloads/',
            en: '/en/downloads/',
        },
    },
    {
        key: 'imprint',
        paths: {
            de: '/info/impressum/',
            en: '/en/info/imprint/',
        },
    },
    {
        key: 'tos',
        paths: {
            de: '/info/agb/',
            en: '/en/info/tos/',
        },
    },
    {
        key: 'privacy-policy',
        paths: {
            de: '/info/datenschutzerklaerung/',
            en: '/en/info/privacy-policy/',
        },
    },
    {
        key: 'homepage',
        paths: {
            de: '/',
            en: '/en/',
        },
    },
    {
        key: 'apartment-search',
        paths: {
            de: '/apartments/suche/',
            en: '/en/apartments/search/',
        },
    },
    {
        key: 'buxtehude1',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1/',
        },
    },
    {
        key: 'bockelkathen',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/bockelkathen/',
            en: '/en/apartments/lueneburg/bockelkathen/bockelkathen/',
        },
    },
    {
        key: 'stade1',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1/',
        },
    },
    {
        key: 'sassnitz',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1/',
        },
    },
    {
        key: 'buxtehude1-1',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1-1/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1-1/',
        },
    },
    {
        key: 'buxtehude1-2',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1-2/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1-2/',
        },
    },
    {
        key: 'buxtehude1-3',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1-3/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1-3/',
        },
    },
    {
        key: 'buxtehude1-4',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1-4/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1-4/',
        },
    },
    {
        key: 'buxtehude1-5',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1-5/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1-5/',
        },
    },
    {
        key: 'buxtehude1-6',
        paths: {
            de: '/apartments/elbe-weser/altesland/buxtehude/bux1-6/',
            en: '/en/apartments/elbe-weser/altesland/buxtehude/bux1-6/',
        },
    },
    {
        key: 'bockelkathen-1',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka01/',
            en: '/en/apartments/lueneburg/bockelkathen/boka01/',
        },
    },
    {
        key: 'bockelkathen-2',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka02/',
            en: '/en/apartments/lueneburg/bockelkathen/boka02/',
        },
    },
    {
        key: 'bockelkathen-3',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka03/',
            en: '/en/apartments/lueneburg/bockelkathen/boka03/',
        },
    },
    {
        key: 'bockelkathen-4',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka04/',
            en: '/en/apartments/lueneburg/bockelkathen/boka04/',
        },
    },
    {
        key: 'bockelkathen-5',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka05/',
            en: '/en/apartments/lueneburg/bockelkathen/boka05/',
        },
    },
    {
        key: 'bockelkathen-6',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka06/',
            en: '/en/apartments/lueneburg/bockelkathen/boka06/',
        },
    },
    {
        key: 'bockelkathen-7',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka07/',
            en: '/en/apartments/lueneburg/bockelkathen/boka07/',
        },
    },
    {
        key: 'bockelkathen-8',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka08/',
            en: '/en/apartments/lueneburg/bockelkathen/boka08/',
        },
    },
    {
        key: 'bockelkathen-9',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka09/',
            en: '/en/apartments/lueneburg/bockelkathen/boka09/',
        },
    },
    {
        key: 'bockelkathen-10',
        paths: {
            de: '/apartments/lueneburg/bockelkathen/boka10/',
            en: '/en/apartments/lueneburg/bockelkathen/boka10/',
        },
    },
    {
        key: 'stade1-1',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-1/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-1/',
        },
    },
    {
        key: 'stade1-2',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-2/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-2/',
        },
    },
    {
        key: 'stade1-3',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-3/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-3/',
        },
    },
    {
        key: 'stade1-4',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-4/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-4/',
        },
    },
    {
        key: 'stade1-5',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-5/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-5/',
        },
    },
    {
        key: 'stade1-6',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-6/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-6/',
        },
    },
    {
        key: 'stade1-7',
        paths: {
            de: '/apartments/elbe-weser/altesland/stade/std1-7/',
            en: '/en/apartments/elbe-weser/altesland/stade/std1-7/',
        },
    },
    {
        key: 'sassnitz-1',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-01/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-01/',
        },
    },
    {
        key: 'sassnitz-2',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-02/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-02/',
        },
    },
    {
        key: 'sassnitz-3',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-03/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-03/',
        },
    },
    {
        key: 'sassnitz-4',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-04/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-04/',
        },
    },
    {
        key: 'sassnitz-5',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-05/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-05/',
        },
    },
    {
        key: 'sassnitz-7',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-07/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-07/',
        },
    },
    {
        key: 'sassnitz-8',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-08/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-08/',
        },
    },
    {
        key: 'sassnitz-9',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-09/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-09/',
        },
    },
    {
        key: 'sassnitz-10',
        paths: {
            de: '/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-10/',
            en: '/en/apartments/ruegen/nationalparkjasmund/sassnitz/sas1-10/',
        },
    },
];

export { allPages };
