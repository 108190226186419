import { TConsentItem } from 'types/consent-items';
import { isBrowser } from './environment';

const getUserConsentFor = (consentItem: TConsentItem) => {
    if (!isBrowser()) return false;
    const hasUserConsent = window.CCM.acceptedEmbeddings.find(
        (item) => item.name === consentItem
    );

    return Boolean(hasUserConsent);
};

export { getUserConsentFor };
