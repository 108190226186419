import { createContext, useContext } from 'react';
import { TLanguageShorthand } from 'types/language-shorthand';
import { DEFAULT_LANG } from '../helpers/language';

type TLangContext = {
    lang: TLanguageShorthand;
    setLang: (lang: TLanguageShorthand) => void;
};

const LangContext = createContext<TLangContext>({
    lang: DEFAULT_LANG,
    setLang: () => {},
});

const useLangContext = () => useContext(LangContext);

export { TLangContext, LangContext, useLangContext };
