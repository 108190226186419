import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TLanguageShorthand } from 'types/language-shorthand';
import { LangContext } from '../../contexts/lang';
import { DEFAULT_LANG, getLangFromPath } from '../../helpers/language';
import '../../sass/general-styling.sass';
import { GoogleAnalytics } from '../google-analytics';
import { Hreflangs } from '../hreflangs';
import t from './translations.json';

declare global {
    interface Window {
        CCM: any;
    }
}

interface IRootLayoutProps {
    children: React.ReactNode;
}

const RootLayout: React.FC<IRootLayoutProps> = ({ children }) => {
    const [lang, setLang] = useState<TLanguageShorthand>(DEFAULT_LANG);
    const [userConsentTimeStamp, setUserConsentTimeStamp] = useState<number>(
        Date.now()
    );

    useEffect(() => {
        const langFromPath = getLangFromPath();
        // could be null, since / is german but has no /de/ prefix.
        // then the state must not be changed from the DEFAULT_LANG (de)
        if (langFromPath !== null) {
            setLang(langFromPath as TLanguageShorthand);
        }

        const updateUserConsentTimeStamp = () =>
            setUserConsentTimeStamp(Date.now());

        window.addEventListener('ccm19WidgetClosed', () =>
            updateUserConsentTimeStamp()
        );

        return () => {
            window.removeEventListener('ccm19WidgetClosed', () =>
                updateUserConsentTimeStamp()
            );
        };
    }, []);

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: lang,
                }}
            >
                <meta name="robots" content="index, follow, archive" />
                <title>{t.metaTitle[lang]}</title>
                <meta
                    name="description"
                    content={t.metaDescription[lang]}
                ></meta>
                <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
                <link
                    rel="icon"
                    type="image/png"
                    href="/favicon@32x32.png"
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href="/favicon@96x96.png"
                    sizes="96x96"
                />
                {/* keep gmap from loading google fonts by overriding insertBefore method of <head> */}
                {/* see: https://gist.github.com/boffey/8516b0091649161cb09e244d63076256 */}
                <script>
                    {`
                        var head = document.getElementsByTagName('head')[0];

                        // Save the original method
                        var insertBefore = head.insertBefore;

                        // Replace it!
                        head.insertBefore = function (newElement, referenceElement) {

                            if (newElement.href && newElement.href.startsWith('https://fonts.googleapis.com/')) {
                                return;
                            }

                            insertBefore.call(head, newElement, referenceElement);
                        };
                    `}
                </script>
                <script
                    src="https://cloud.ccm19.de/app.js?apiKey=e161cb7ae1a1f5adef7635580b2bd1f47d6f5c672ae1a4ec&amp;domain=620a6107c09c7541a57ba3c0"
                    referrerPolicy="origin"
                ></script>
            </Helmet>

            <GoogleAnalytics />

            <Hreflangs />

            <LangContext.Provider value={{ lang, setLang }}>
                {children}
            </LangContext.Provider>
        </>
    );
};

export { RootLayout };
