import React from 'react';
import { Helmet } from 'react-helmet';
import { isBrowser, isProductiveEnv } from '../../helpers/environment';
import { getUserConsentFor } from '../../helpers/userConsent';

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (
            title: string,
            actionName: string | Date,
            parameters?: {
                [key in string]: string;
            }
        ) => void;
    }
}

const GoogleAnalytics = () => {
    if (
        !isBrowser() ||
        !getUserConsentFor('Google Analytics') ||
        !isProductiveEnv()
    ) {
        return null;
    }

    return (
        <Helmet>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-135SC0B2Y0"
            />
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-135SC0B2Y0');
                `}
            </script>
        </Helmet>
    );
};

export { GoogleAnalytics };
